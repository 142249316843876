import type { Bid, Cart } from '@medusajs/medusa';
import type { PricedProduct } from '@medusajs/medusa/dist/types/pricing';
import { getMinimumProductPriceValue } from '../prices';
import { isOrder } from '../types';
import { type DispatchEvent, type EventInputData } from './events';
import {
  type KlaviyoActionsProfile,
  extractProfileFromCart,
  extractProfileFromOrder,
  getAddedCartItems,
  getItemsFromProducts,
  getRemovedCartItems,
  getUpdatedCartValue,
  identifySegmentUser,
  lineItemMap,
  selectCheckoutDataFromCart,
  selectCheckoutDataFromEntity,
  selectProductDataFromAuction,
  selectProductDataFromCart,
  selectProductDataFromOrder,
  selectValueFromCart,
  setUserDataFromCustomer,
} from './helpers';
import { type GA4ItemParams, KlaviyoListId, type ShareContentMethods } from './types';

export type EventProcessor<
  EventName extends keyof EventInputData = keyof EventInputData,
  ProcessedData = EventInputData[keyof EventInputData],
> = (
  eventName: EventName,
  dispatch: DispatchEvent<EventName, ProcessedData>,
  data: EventInputData[EventName],
) => ReturnType<DispatchEvent<EventName, ProcessedData>>;

export const defaultProcessor = <EventName extends keyof EventInputData>(
  event: EventName,
  dispatch: DispatchEvent<EventName, EventInputData[EventName]>,
  data: EventInputData[EventName],
) => dispatch(event, data);
interface ProcessedShareData extends Omit<ShareData, 'files'> {
  method: ShareContentMethods;
}

export const segmentSignUpProcessor: EventProcessor<'sign_up'> = (_eventName, dispatch, data) => {
  return dispatch('Sign Up' as 'sign_up', data);
};

export const gaLoginProcessor: EventProcessor<'login', { method: string }> = (
  eventName,
  dispatch,
  { method, customer },
) => {
  setUserDataFromCustomer(customer);
  return dispatch(eventName, { method });
};

export const segmentLoginProcessor: EventProcessor<'login'> = (_eventName, dispatch, data) => {
  return dispatch('Login' as 'login', data);
};

export const segmentLogoutProcessor: EventProcessor<'logout'> = (_eventName, dispatch, data) => {
  return dispatch('Logout' as 'logout', data);
};

export const segmentShareProcessor: EventProcessor<'share', ProcessedShareData> = (
  _eventName,
  dispatch,
  { method, url, title, text },
) =>
  dispatch('Share' as 'share', {
    method,
    url,
    title,
    text,
  });

export const segmentSearchProcessor: EventProcessor<'search'> = (_eventName, dispatch, data) => {
  return dispatch('Search' as 'search', data);
};

export const gaViewItemProcessor: EventProcessor<
  'view_item',
  { currency: string; value: number; items: GA4ItemParams[] }
> = (eventName, dispatch, { product, currencyCode }) => {
  return dispatch(eventName, {
    currency: currencyCode,
    value: getMinimumProductPriceValue(product as unknown as PricedProduct, currencyCode) / 100,
    items: getItemsFromProducts([product], currencyCode),
  });
};

export const gaViewCartProcessor: EventProcessor<
  'view_cart',
  { currency: string; value: number | undefined; items: GA4ItemParams[] }
> = (eventName, dispatch, { cart }) => {
  return dispatch(eventName, {
    currency: cart.region.currency_code,
    value: selectValueFromCart(cart),
    items: cart.items.map(lineItemMap),
  });
};

export const gaAddCartItemsProcessor: EventProcessor<
  'add_to_cart',
  { currency: string; value: number | undefined; items: GA4ItemParams[] }
> = (eventName, dispatch, { updatedCart, previousCart }) =>
  dispatch(eventName, {
    currency: updatedCart.region.currency_code,
    value: getUpdatedCartValue(updatedCart, previousCart),
    items: getAddedCartItems(updatedCart, previousCart),
  });

export const gaRemoveCartItemsProcessor: EventProcessor<
  'remove_from_cart',
  { currency: string; value: number | undefined; items: GA4ItemParams[] }
> = (eventName, dispatch, { updatedCart, previousCart }) =>
  dispatch(eventName, {
    currency: updatedCart.region.currency_code,
    value: getUpdatedCartValue(updatedCart, previousCart),
    items: getRemovedCartItems(updatedCart, previousCart),
  });

export const segmentBeginCheckoutProcessor: EventProcessor<
  'begin_checkout',
  {
    profile: KlaviyoActionsProfile;
    product: ReturnType<typeof selectProductDataFromCart>;
    bid: Bid;
    currency: string;
  }
> = async (_eventName, dispatch, { cart }) => {
  const product = selectProductDataFromCart(cart);
  const profile = extractProfileFromCart(cart);

  return dispatch('Checkout Flow Started' as 'begin_checkout', {
    profile,
    product,
    bid: cart.bid as Bid,
    currency: cart.region.currency_code,
  });
};

export const gaBidCompletedProcessor: EventProcessor<
  'bid_completed',
  { currency: string; value: number; items: GA4ItemParams[]; coupon?: string } | undefined
> = async (_eventName, dispatch, { entity }) => {
  const bid = isOrder(entity) ? entity.cart?.bid : (entity as Cart).bid;
  if (!bid) return;

  return dispatch('bid' as 'bid_completed', selectCheckoutDataFromEntity(entity));
};

export const segmentBidCompletedProcessor: EventProcessor<
  'bid_completed',
  {
    profile: KlaviyoActionsProfile;
    product: ReturnType<typeof selectProductDataFromCart>;
    bid: Bid | null;
    revenue: number;
    currency: string;
  }
> = async (_eventName, dispatch, { entity }) => {
  const isOrderEntity = isOrder(entity);
  const product = isOrderEntity ? selectProductDataFromOrder(entity) : selectProductDataFromCart(entity as Cart);
  const bid = isOrderEntity ? entity.cart?.bid : (entity as Cart).bid;

  const totalRevenue = isOrderEntity ? (entity.total ?? 0) / 100 : 0;

  return dispatch('Checkout Flow Completed' as 'bid_completed', {
    profile: isOrderEntity ? extractProfileFromOrder(entity) : extractProfileFromCart(entity as Cart),
    product,
    bid: bid as Bid,
    revenue: totalRevenue,
    currency: entity.region.currency_code,
  });
};

export const segmentSubscribeProcessor: EventProcessor<
  'subscribe',
  {
    email: string;
    firstName?: string;
    lastName?: string;
    listId: KlaviyoListId;
    source: string;
  }
> = async (eventName, dispatch, { email, customer }) => {
  await identifySegmentUser({ email, ...customer });

  const source = window?.location?.href;
  return dispatch('Subscribe' as 'subscribe', {
    email: email || customer?.email || '',
    firstName: customer?.first_name || '',
    lastName: customer?.last_name || '',
    listId: KlaviyoListId.AshleyCanadaNewsletter,
    source,
  });
};

export const gaBeginCheckoutProcessor: EventProcessor<
  'begin_checkout',
  { currency: string; value: number; items: GA4ItemParams[]; coupon?: string }
> = (eventName, dispatch, { cart }) => dispatch(eventName, selectCheckoutDataFromCart(cart));

export const gaAddShippingInfoProcessor: EventProcessor<
  'add_shipping_info',
  { currency: string; value: number; items: GA4ItemParams[]; coupon?: string; shipping_tier: string }
> = (eventName, dispatch, { cart }) =>
  dispatch(eventName, {
    ...selectCheckoutDataFromCart(cart),
    shipping_tier: cart?.shipping_methods.map((shippingMethod) => shippingMethod?.shipping_option?.name).join(','),
  });

export const segmentPostalCodeEnteredProcessor: EventProcessor<'postal_code_entered'> = (
  _eventName,
  dispatch,
  data,
) => {
  return dispatch('Postal Code Entered' as 'postal_code_entered', data);
};

export const segmentModalOpenedProcessor: EventProcessor<'modal_opened', { source: string }> = (
  _eventName,
  dispatch,
  { name },
) => {
  const source = window?.location?.href;
  return dispatch(`${name} Modal Opened` as 'modal_opened', { source });
};

export const segmentModalClosedProcessor: EventProcessor<'modal_closed', { source: string }> = (
  _eventName,
  dispatch,
  { name },
) => {
  const source = window?.location?.href;
  return dispatch(`${name} Modal Closed` as 'modal_closed', { source });
};

export const segmentNavigationProcessor: EventProcessor<'navigation', { source: string; destination: string }> = (
  _eventName,
  dispatch,
  { type, ...data },
) => {
  const source = window?.location?.href;
  return dispatch(`${type} Navigation` as 'navigation', { ...data, source });
};

export const segmentProductPhotoClickProcessor: EventProcessor<
  'product_photo_click',
  { product: ReturnType<typeof selectProductDataFromAuction>; image_index: number }
> = (eventName, dispatch, { auction, image_index }) => {
  const product = selectProductDataFromAuction(auction);

  return dispatch('Product Photo Clicked' as 'product_photo_click', { product, image_index });
};

export const segmentBidOrBuyNowActionProcessor: EventProcessor<
  'bid_or_buy_now_action',
  { product: ReturnType<typeof selectProductDataFromAuction>; description: string }
> = (eventName, dispatch, { auction, description }) => {
  const product = selectProductDataFromAuction(auction);

  return dispatch('CTA Description Clicked' as 'bid_or_buy_now_action', { product, description });
};
