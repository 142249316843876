import { useCallback } from 'react';
import { useEnv } from '../../ui-components/hooks/useEnv';
import { events, type AnalyticsConsumer, type DispatchEvent, type EventInputData } from './events';
import { useSendGAEvent } from './google/google';
import { type EventProcessor, defaultProcessor } from './processors';
/* eslint-disable no-console */
import { useSendSegmentEvent } from './segment/segment';

type DispatchMap<T> = {
  [key in AnalyticsConsumer]: DispatchEvent<keyof EventInputData, T>;
};

export const useDispatchMap = <T>(): DispatchMap<T> => {
  const sendGAEvent = useSendGAEvent<T>();
  const sendSegmentEvent = useSendSegmentEvent<T>();

  return {
    ga: sendGAEvent,
    segment: sendSegmentEvent,
  };
};

export function useSendEvent<EventName extends keyof EventInputData>(eventName: EventName) {
  const {
    env: { EVENT_LOGGING, SMOKE_TEST },
  } = useEnv();

  if (SMOKE_TEST === 'ENABLED') {
    return () => {
      return console.log(`Smoke test event: ${SMOKE_TEST}`);
    };
  }

  const dispatchMap = useDispatchMap<EventInputData[EventName]>();
  const eventConfig = events[eventName];
  const consumers = Object.keys(eventConfig) as AnalyticsConsumer[];
  const sendEvent = useCallback(
    (
      data: EventInputData[EventName],
      options: {
        onSuccess?: () => void;
        onError?: (error: Error) => void;
      } = {},
    ) => {
      const consumerPromises = consumers.map((consumer) => {
        const selectedProcessor = eventConfig[consumer];
        if (!selectedProcessor) return;
        const processor =
          typeof selectedProcessor === 'function' ? selectedProcessor : (defaultProcessor as EventProcessor<EventName>);

        const dispatcher = async (action: EventName, params: EventInputData[EventName]) => {
          const result = await dispatchMap[consumer](action, params);
          if (EVENT_LOGGING === 'true' || EVENT_LOGGING === 'TRUE') {
            console.group(`%c[${action}](${consumer})`, 'font-weight: bold;');
            console.log('input data:', data);
            console.log('sent data:', result);
            console.groupEnd();
          }
          return result;
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return processor(eventName, dispatcher as DispatchEvent<EventName, any>, data).then((processedData) => {
          return processedData as typeof data;
        });
      });

      // Note: we are not using `await Promise.all(consumerPromises)` so we do not need to await everywhere we use the callback from this hook
      Promise.all(consumerPromises)
        .then(() => {
          if (options.onSuccess) options.onSuccess();
        })
        .catch((error: Error) => {
          if (options.onError) options.onError(error);
          console.error('Error processing event:', error);
        });
    },
    [consumers, dispatchMap, eventConfig, EVENT_LOGGING, eventName],
  );

  return sendEvent;
}
